<script setup lang="ts"></script>

<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.87098 20C7.79316 20 7.71441 19.9845 7.63918 19.952C7.37586 19.8384 7.23344 19.551 7.30258 19.2727L9.28398 11.297H3.94533C3.75209 11.297 3.57127 11.2018 3.46201 11.0423C3.35275 10.8829 3.32912 10.6799 3.39884 10.4997L7.31531 0.374569C7.40262 0.148828 7.61973 0 7.86176 0H13.2508C13.4488 0 13.6335 0.0999996 13.7417 0.265858C13.8498 0.431717 13.8669 0.640974 13.787 0.822184L11.468 6.08623H16.0547C16.269 6.08623 16.4661 6.20322 16.5689 6.3913C16.6716 6.57935 16.6635 6.80849 16.5477 6.9888L8.36425 19.7306C8.25359 19.903 8.06539 20 7.87098 20Z"
            fill="url(#paint0_linear_spark_273_886)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_spark_273_886"
                x1="19"
                y1="1.11719"
                x2="19"
                y2="36.8835"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
        </defs>
    </svg>
</template>
